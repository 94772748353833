<template>
  <transition name="slide" mode="in-out">
    <div class="sms-login" v-if="isShow">
      <div class="sms-login__msg">
        <p>Введите одноразовый пароль из SMS</p>
      </div>
      <div class="sms-login__body">
        <div class="sms-login__body-code">
          <rwm-text-field :width="fieldWidth" label="SMS код" v-model="typedCode" />
        </div>
        <div class="sms-login__body-resend">
          <rwm-underline-btn
              :class="{'blink' : timerEnds}"
              @click="timerEnds ? localStartTimer() : ''"
          >
            Прислать повторно (<timer :timer-enabled="timer" @endTimer="endTimer()" :timer="time" />)
          </rwm-underline-btn>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import RwmTextField from "@/components/RWMTextField/RwmTextField";
import RwmUnderlineBtn from "@/components/RWMUnderlineButton/RwmUnderlineBtn";
import Timer from "@/components/Timer/Timer";
export default {
  name: "RwmSmsChecking",
  components: {Timer, RwmUnderlineBtn, RwmTextField},
  props: {
    timer: Boolean,
    isShow: {
      type: Boolean,
      default: false
    },
    time: {
      type: Number,
      required: true
    },
    fieldWidth: {
      type: String,
      default: "187"
    },
  },
  model: {
    prop: 'timer',
    event: 'timer',
  },
  data() {
    return {
      timerEnds: false,
      typedCode: ""
    }
  },
  methods: {
    localStartTimer() {
      if (this.timerEnds) {
        this.typedCode = ""
        this.$emit('sendAgain')
      }
    },
    startTimer() {
      this.timerEnds = false
    },
    endTimer() {
      this.timerEnds = true
      this.$emit('timer')
    }
  },
  watch: {
    timer: {
      handler(value) {
        if(value) this.$nextTick(this.startTimer())
      }
    },
    typedCode: {
      handler(value) {
        if(value.length === 4) this.$emit('verified', value)
      }
    }
  },
}
</script>
<style scoped lang="scss">
.sms-login {
  display: block;
  column-gap: 10px;
  &__msg {
    color: $RWM-grey;
    font-weight: 400;
    font-size: 21px;
    line-height: 29px;
    width: 100%;
    margin-bottom: 30px;
  }
  &__body {
    display: flex;
    justify-content: space-between;
    column-gap: 10px;
    &-code {
    }
    &-resend {
      max-height: 58px;
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      text-align: right;
    }
  }
}
</style>
