<template>
  <span>{{ parseToTime.minutes }}:{{ parseToTime.seconds }}</span>
</template>

<script>

export default {
  name: "Timer",
  props: {
    timer: {
      type: Number,
      default: 60
    },
    timerEnabled: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    parseToTime() {
      let time = {
        minutes: (this.timerCount / 60) | 0,
        seconds: (this.timerCount % 60) | 0
      }
      time.minutes = time.minutes < 10 ? "0"+time.minutes : time.minutes
      time.seconds = time.seconds < 10 ? "0"+time.seconds : time.seconds
      return time
    }
  },
  data() {
    return {
      timerCount: 0,
    }
  },

  watch: {
    timerEnabled: {
      handler(value) {
        this.$nextTick(()=> {value ? this.timerCount = this.timer : '';})
      },
      immediate: true
    },
    timerCount(value) {
      if (value > 0 && this.timerEnabled) {
        setTimeout(() => {
          this.timerCount--;
        }, 1000);
      }
      if (value === 0) {
        this.$emit('endTimer')
      }
    }
  },
}

</script>
