<template>
  <div class="underlined-btn">
    <router-link v-if="to" :to="to" v-bind="$attrs" custom v-slot="{ navigate }">
      <span @click="navigate" @keypress.enter="navigate" role="link"><slot /></span>
    </router-link>
    <span v-else @click="$emit('click')">
      <slot />
    </span>
  </div>
</template>

<script>
export default {
  name: "RwmUnderlineBtn",
  props: {
    to: {
      type: String,
      default: undefined
    }
  }
}
</script>

<style scoped lang="scss">
  .underlined-btn {
    & span {
      color: $RWM-grey;

      font-weight: normal;
      font-size: 19px;
      line-height: 26px;
      cursor: pointer;
      text-decoration: underline;
      transition: all .3s;

      @media screen and (min-width: 961px){
        &:hover,
        &:hover span {
          color: $RWM-red;
        }
      }
    }
    &.blink {
      & span {
        animation: blinking 2s linear;
      }
    }
  }
  @keyframes blinking {
    50% {color: $RWM-red}
  }
</style>
